import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken} from "@/utils/request/auth";

NProgress.configure({showSpinner: false})

const notAuthPattern = /(\/login|\/register|\/forgot|\/page\.aspx)/;
router.beforeEach((to, from, next) => {
    NProgress.start()
    let requireAuth = !notAuthPattern.test(to.path);
    let isLogin = notAuthPattern.test(to.path)
    let token = getToken();
    
    //需要token认证才能进入
    if (requireAuth) {
        if (token) {
            next()
        } else {
            next(`/login`) // 否则全部重定向到登录页
        }
    }
    // 不需要认证
    else {
        if (isLogin && token) {
            next("/")
        } else {
            next()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
