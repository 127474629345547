import copy from 'copy-to-clipboard';
import cloneDeep from "clone-deep";
import {throttle} from 'throttle-debounce'
import {Message} from "element-ui";
import moment from "moment";
import CountryFlag from 'vue-country-flag'

const install = (Vue) => {
    Vue.component('vue-country-flag', CountryFlag);
    //文件地址
    // Vue.prototype.filePath = process.env.VUE_APP_FILE_PATH
    //eventBus
    Vue.prototype.eventBus = new Vue()
    Vue.prototype.debounceFunc = (wait, fn) => {
        let timeout, result;
        return function () {
            const context = this
            const args = arguments
            clearTimeout(timeout)
            const callNow = !timeout
            timeout = setTimeout(function () {
                timeout = null
            }, wait)
            if (callNow) result = fn.apply(context, args)
            return result
        }
    }//防抖
    Vue.prototype.throttleFunc = throttle//节流

    Vue.prototype.moment = moment//日期转换
    Vue.prototype.md5Pre = "cloud-phone"
    Vue.filter('formatDate', function (value) {
        if (!value) return ''
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
    })
    Vue.filter('hidePhone', function (value) {
        if (!value) return ''
        value = value.toString()
        return value.replace(/(\d{3})(\d{4})(\d{4})/g, "$1****$3");
    })
    Vue.filter('addImgPre', function (value) {
        if (!value) return ''
        value = value.toString()
        return `${process.env["VUE_APP_IMG_PATH"]}${value}`;
    })
    Vue.filter('hidePassword', function (value, isHide = true) {
        if (!value) return ''
        value = value.toString()
        if (isHide) return value.replace(/\S/g, "*")
        return value;
    })
    //时间转换
    Vue.prototype.transferDate = value => {
        return new Date(value).toLocaleString().replace(/\//g, "-")
    }
    // 深度克隆
    Vue.prototype.cloneDeep = cloneDeep;
    // 复制到剪切板
    Vue.prototype.copyToClipboard = (value) => {
        copy(value);
        Message.success("复制成功")
        // Message.success("复制成功")
    };
    //区间
    Vue.prototype.range = (min, max) => {
        return Array.from({length: (max - min + 1)}).reduce((list, item, index) => list.concat([min + index]), [])
    }
    //(time, option)
    Vue.filter('formatTime', function (time) {
        // if (('' + time).length === 10) {
        //     time = parseInt(time) * 1000
        // } else {
        //     time = +time
        // }
        time = (time + "").replace(/-/g, "/")
        const d = new Date(time)
        const now = Date.now()

        const diff = (now - d) / 1000

        if (diff < 30) {
            return '刚刚'
        } else if (diff < 3600) {
            // less 1 hour
            return Math.ceil(diff / 60) + '分钟前'
        } else if (diff < 3600 * 24) {
            return Math.ceil(diff / 3600) + '小时前'
        } else if (diff < 3600 * 24 * 2) {
            return '1天前'
        } else {
            return Math.ceil(diff / (3600 * 24)) + "天前"
        }
        // if (option) {
        //     return parseTime(time, option)
        // } else {
        //     return (
        //         d.getMonth() +
        //         1 +
        //         '月' +
        //         d.getDate() +
        //         '日' +
        //         d.getHours() +
        //         '时' +
        //         d.getMinutes() +
        //         '分'
        //     )
        // }
    })

    /**
     * 指令
     */
    Vue.directive('favicon', {
        // 当被绑定的元素插入到 DOM 中时……
        inserted: function (el, binding) {
            let faviconDom = document.querySelector("link[rel*='icon']");
            faviconDom.href = binding.value;
        }
    })
    Vue.directive('title', {
        // 当被绑定的元素插入到 DOM 中时……
        inserted: function (el, binding) {
            let faviconDom = document.querySelector("link[rel*='icon']");
            faviconDom.href = binding.value;
        }
    })
    Vue.directive('click-outside', {
        bind(el, binding) {
            function documentHandler(e) {
                // 如果是元素本体则返回
                if (el.contains(e.target)) return false;
                if (binding.expression) {
                    // 有绑定函数，则执行
                    binding.value(e);
                }
            }

            el.__vueClickOutside__ = documentHandler;
            document.addEventListener('click', documentHandler);
        },
        update() {
        },
        unbind(el) {
            document.removeEventListener('click', el.__vueClickOutside__);
            delete el.__vueClickOutside__;
        },
    })
}
// 日期格式化
// function parseTime(time, pattern) {
//     if (arguments.length === 0 || !time) {
//         return null
//     }
//     const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
//     let date
//     if (typeof time === 'object') {
//         date = time
//     } else {
//         if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
//             time = parseInt(time)
//         } else if (typeof time === 'string') {
//             time = time.replace(new RegExp(/-/gm), '/');
//         }
//         if ((typeof time === 'number') && (time.toString().length === 10)) {
//             time = time * 1000
//         }
//         date = new Date(time)
//     }
//     const formatObj = {
//         y: date.getFullYear(),
//         m: date.getMonth() + 1,
//         d: date.getDate(),
//         h: date.getHours(),
//         i: date.getMinutes(),
//         s: date.getSeconds(),
//         a: date.getDay()
//     }
//     const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
//         let value = formatObj[key]
//         // Note: getDay() returns 0 on Sunday
//         if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
//         if (result.length > 0 && value < 10) {
//             value = '0' + value
//         }
//         return value || 0
//     })
//     return time_str
// }
export default {
    install
}
