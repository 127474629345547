/**
 * 设置缓存数据
 * @param key
 * @param value
 */
export function setLocalstorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

/**
 * 获取缓存数据
 * @param key
 */
export function getLocalstorage(key) {
    return JSON.parse(localStorage.getItem(key))
}

/**
 * 清理本地缓存
 */
export function clearLocalstorage() {
    localStorage.clear()
}