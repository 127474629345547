const layoutRoutes = [
    {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
        meta:{
            title:"主页"
        }
    },
    //菜单
    {
        path: 'menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '@/views/menu'),
        meta:{
            title:"菜单"
        }
    },
    {
        path: 'menu/add-edit',
        name: 'menuAddEdit',
        component: () => import(/* webpackChunkName: "menu/add-edit" */ '@/views/menu/add-edit'),
        meta:{
            title:"菜单添加编辑"
        }
    },
    //产品
    {
        path: 'product',
        name: 'product',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product'),
        meta:{
            title:"产品"
        }
    },
    {
        path: 'product/add-edit',
        name: 'productAddEdit',
        component: () => import(/* webpackChunkName: "classify/add-edit" */ '@/views/product/add-edit'),
        meta:{
            title:"产品添加编辑"
        }
    },
    //分类
    {
        path: 'classify',
        name: 'classify',
        component: () => import(/* webpackChunkName: "classify" */ '@/views/classify'),
        meta:{
            title:"分类"
        }
    },
    {
        path: 'classify/add-edit',
        name: 'classifyAddEdit',
        component: () => import(/* webpackChunkName: "classify/add-edit" */ '@/views/classify/add-edit'),
        meta:{
            title:"分类添加编辑"
        }
    }
]
export default layoutRoutes
