import Vue from 'vue'
import VueRouter from 'vue-router'
import layoutRoutes from "@/router/layout"


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        redirect: '/home',
        children: layoutRoutes,
        component: () => import(/* webpackChunkName: "layout" */ '@/components/Layout')
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
        meta:{
            title:"登录"
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
