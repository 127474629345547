import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon' // svg component

// register globally
Vue.component('svg-icon', SvgIcon)

const req = require.context('./svg', true, /\.svg$/)// 深度检测
const requireAll = requireContext => requireContext.keys().map(requireContext)

requireAll(req)
