import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {getToken} from '@/utils/request/auth'
import errorCode from '@/utils/request/errorCode'
import {MessageBox} from 'element-ui'
// xss
import * as xss from "xss";
import qs from "qs";

let xssOptions = {
    whiteList: {}, // 白名单为空，表示过滤所有标签
    stripIgnoreTag: true, // 过滤所有非白名单标签的HTML
    stripIgnoreTagBody: ["script"], // script标签较特殊，需要过滤标签中间的内容
}
let myXss = new xss.FilterXSS(xssOptions);

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 5 * 1000
})
// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    let token = getToken()
    // console.log('token:',token)
    if (token) {
        config.headers['authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        // let url = config.url + '?';
        // for (const propName of Object.keys(config.params)) {
        //     const value = config.params[propName];
        //     const part = encodeURIComponent(propName) + "=";
        //     if (value !== null && typeof (value) !== "undefined") {
        //         if (typeof value === 'object') {
        //             for (const key of Object.keys(value)) {
        //                 let params = propName + '[' + key + ']';
        //                 const subPart = encodeURIComponent(params) + "=";
        //                 url += subPart + encodeURIComponent(value[key]) + "&";
        //             }
        //         } else {
        //             url += part + encodeURIComponent(value) + "&";
        //         }
        //     }
        // }
        // url = url.slice(0, -1);
        // config.params = {}
        // config.url = url;
        // console.log(config.url)

        config.paramsSerializer = params => {
            return qs.stringify(params, { indices: false })
        }
    }
    if ((config.method === 'post' || config.method === 'put') && config.data) {
        if (!config.url.includes('/upload')) {
            let dataStr = JSON.stringify(config.data)
            const afterDealDataStr = myXss.process(dataStr)
            if (dataStr.length !== afterDealDataStr.length) {
                const CancelToken = axios.CancelToken;
                config.cancelToken = new CancelToken((cancel) => cancel('包含非法字符'))
            } else {
                config.data = JSON.parse(afterDealDataStr)
            }
        }
    }
    return config
}, error => {
    Promise.reject(error).then(() => {
    })
})

// 响应拦截器
service.interceptors.response.use(res => {
        // console.log("请求返回",res)
        // 未设置状态码则默认成功状态
        const code = res.data.code;
        //处理blob
        if(res.config.responseType==='blob'){
            return {
                code:0,
                data:res.data
            }
        }
        // 单独处理文件证书下载
        if (res.headers['content-type'] === 'application/zip') {
            return {
                code: 0,
                data: res.data
            }
        }
        // if(res.headers.ac)
        // 获取错误信息
        const msg = errorCode[code] || res.data.message || errorCode['default']
        if (code !== 0) {
            if (msg && msg.length > 0) {
                // Message.error(msg)
                console.error(msg)
            }
            return Promise.reject(msg)
        } else {
            return res.data
        }
    },
    error => {
        let {message} = error;
        if (message === "包含非法字符") {
            message = "包含非法字符";
        } else if (message === "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else {
            message = errorCode[error.response.status] || errorCode['default'];
        }
        if (error && error.response && error.response.status === 401) {
            MessageBox.confirm('登录状态已过期或账号已冻结，您可以继续留在该页面，或者重新登录', '系统提示', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                store.dispatch("ReLogin").then(() => {
                    router.replace("/login");
                })
            });
            store.dispatch("ReLogin").then(() => {
                router.replace("/login").then(() => {
                });
            })
        } else {
            if (message && message.length > 0) {
                // Message.error(message)
                console.error(message)
            }
        }
        return Promise.reject(message)
    }
)

export default service
