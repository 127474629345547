import {login} from '@/utils/api/login'
import {userInfo} from "@/utils/api/user";
import {clearLocalstorage} from "@/utils/localstorage";
import {getToken, removeToken, setToken} from '@/utils/request/auth'

const user = {
    state: {
        token: getToken(),
        info: null
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_INFO: (state, info) => {
            state.info = info
        }
    },

    actions: {
        // 账号登录
        Login({commit}, accountInfo) {
            return new Promise((resolve, reject) => {
                login(accountInfo).then(res => {
                    if (res) {
                        setToken(res.data.token)
                        commit('SET_TOKEN', res.data.token)
                    }
                    resolve(res.data.token)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户信息
        getUserInfo({commit}) {
            return new Promise((resolve, reject) => {
                userInfo().then(res => {
                    commit('SET_INFO', res.data)
                    resolve(res.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 退出登录
        LoginOut({commit}) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '')
                removeToken()
                clearLocalstorage()//清理缓存
                resolve()
            })
        },
        // 重新登录
        ReLogin({commit}) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '')
                removeToken()
                clearLocalstorage()//清理缓存
                resolve()
            })
        }
    }
}

export default user
