import request from '@/utils/request'

//新增
export function login(data) {
    return request({
        url: '/admin/login',
        method: 'post',
        data
    })
}
