/**
 * 分页
 * @type {number[]}
 */
export const pageSizes = [5, 10, 15, 20, 30, 50, 100];
export const brandMap = {
    '0':'否',
    '1':'是'
}
export const serverAddress = process.env["VUE_APP_BASE_API_REMOTE"]
